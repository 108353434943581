import { Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FooterCss from "./Footer.module.css";
import {
  FaYoutube,
  FaFacebookF,
  FaPinterest,
  FaSnapchatGhost,
  FaTiktok,
} from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import { FaXTwitter } from "react-icons/fa6";
import { SERVER_URL } from "../../Constants.ts";
import axios from "axios";
import DynamicContentInjector from "../DynamicContentInjector/DynamicContentInjector.js";

export default function Footer() {
  const [bannerAd, setBannerAd] = useState(null);
  function openLink(item) {
    let getLink;
    if (item === "youtube") {
      getLink = "https://www.youtube.com/trinijunglejuice";
    }
    if (item === "facebook") {
      getLink = "https://www.facebook.com/trinijunglejuice";
    }
    if (item === "twitter") {
      getLink = "https://twitter.com/junglejuiceintl";
    }
    if (item === "instagram") {
      getLink = "https://www.instagram.com/trinijunglejuice/";
    }
    if (item === "pinterest") {
      getLink = "https://www.pinterest.com/trinijunglejuice/";
    }
    if (item === "snapchat") {
      getLink = "https://www.snapchat.com/add/trinijunglejuic";
    }
    if (item === "tiktok") {
      getLink = "https://www.tiktok.com/@trinijunglejuice";
    }
    const aTag = document.createElement("a");
    aTag.setAttribute("href", getLink);
    aTag.setAttribute("target", "_blank");
    aTag.click();
  }

  const handleGetFooterBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "footer ad",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  useEffect(() => {
    handleGetFooterBanner();
  }, []);

  return (
    <>
      <div className={FooterCss.mainDiv}>
        <Grid className={FooterCss.marqueeDiv}>
          <marquee className={FooterCss.marquee}>
            Disclaimer Notice: TriniJungleJuice.com is not responsible for the
            cancellation, change of date or venue of events. Always check with
            the event promoter(s) for accuracy of the information provided
            herein. We are not responsible for typos, accuracy, and/or other
            errors and omissions in the information provided by other parties.
            TriniJungleJuice.com is not affiliated with any promotions or
            promoters, unless specifically stated.
          </marquee>
          <Container>
            <div className={FooterCss.subDiv}>
              <Grid container rowGap={5}>
                <Grid item xs={12} md={4} lg={4} className={FooterCss.imgDiv}>
                  <img
                    src={require("../../assets/images/tjj-logo.png")}
                    className={FooterCss.footerImage}
                  />
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <div className={FooterCss.navigateDiv}>
                    <a className={FooterCss.navigate} href="/store">
                      Store
                    </a>
                    <a className={FooterCss.navigate} href="/carnival">
                      Carnival
                    </a>
                    <a className={FooterCss.navigate} href="/events">
                      Events
                    </a>
                    <a className={FooterCss.navigate} href="/gallery">
                      Photos
                    </a>
                  </div>
                </Grid>
                <Grid item xs={6} md={2} lg={2}>
                  <div className={FooterCss.navigateDiv}>
                    <a className={FooterCss.navigate} href="/videos">
                      Videos
                    </a>
                    <a className={FooterCss.navigate} href="/music">
                      Music
                    </a>
                    <a className={FooterCss.navigate} href="/news">
                      News
                    </a>
                    <a className={FooterCss.navigate} href="/contact">
                      Contact
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <div className={FooterCss.rightDiv}>
                    <div>221B, Baker Street, 90265</div>
                    <div className={FooterCss.socials}>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title="Youtube"
                        placement="bottom"
                        arrow
                        classes={{ tooltip: FooterCss.socialsText }}
                        children={
                          <div>
                            <FaYoutube
                              onClick={() => openLink("youtube")}
                              className={FooterCss.icon}
                            />
                          </div>
                        }
                      />
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title="Facebook"
                        placement="bottom"
                        arrow
                        classes={{ tooltip: FooterCss.socialsText }}
                        children={
                          <div>
                            <FaFacebookF
                              onClick={() => openLink("facebook")}
                              className={FooterCss.icon}
                            />
                          </div>
                        }
                      />
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title="Twitter"
                        placement="bottom"
                        arrow
                        classes={{ tooltip: FooterCss.socialsText }}
                        children={
                          <div>
                            <FaXTwitter
                              onClick={() => openLink("twitter")}
                              className={FooterCss.icon}
                            />
                          </div>
                        }
                      />
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title="Instagram"
                        placement="bottom"
                        arrow
                        classes={{ tooltip: FooterCss.socialsText }}
                        children={
                          <div>
                            <RiInstagramFill
                              onClick={() => openLink("instagram")}
                              className={FooterCss.icon}
                            />
                          </div>
                        }
                      />
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title="Pinterest"
                        placement="bottom"
                        arrow
                        classes={{ tooltip: FooterCss.socialsText }}
                        children={
                          <div>
                            <FaPinterest
                              onClick={() => openLink("pinterest")}
                              className={FooterCss.icon}
                            />
                          </div>
                        }
                      />
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title="Snapchat"
                        placement="bottom"
                        arrow
                        classes={{ tooltip: FooterCss.socialsText }}
                        children={
                          <div>
                            <FaSnapchatGhost
                              onClick={() => openLink("snapchat")}
                              className={FooterCss.icon}
                            />
                          </div>
                        }
                      />

                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title="Tiktok"
                        placement="bottom"
                        arrow
                        classes={{ tooltip: FooterCss.socialsText }}
                        children={
                          <div>
                            <FaTiktok
                              onClick={() => openLink("tiktok")}
                              className={FooterCss.icon}
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className={FooterCss.copyrightDiv}>
              {"\u00A9"} 2024 Copyright: Trini Jungle Juice | Made by Renderbit
              Technologies
            </div>
          </Container>
        </Grid>
      </div>
      {bannerAd &&
        (bannerAd?.banner_image_url ? (
          <Container className={FooterCss.fullHeightAds}>
            <img
              className={FooterCss.BannerAds}
              src={bannerAd?.banner_image_url}
            />
          </Container>
        ) : (
          bannerAd?.content && (
            <Container className={FooterCss.fullHeightAds}>
              <DynamicContentInjector
                className={FooterCss.BannerAd}
                htmlContent={bannerAd?.content}
              />
            </Container>
          )
        ))}
    </>
  );
}
