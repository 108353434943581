import React, { useContext, useEffect } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import Label from "../../elements/label/Label";
import Card from "../../components/card/Card";
import { Grid, Skeleton } from "@mui/material";
import GalleryList from "../../elements/Flatlist/GalleryList";
import VideoList from "../../elements/Flatlist/VideoList";
import NewsList from "../../elements/Flatlist/NewsList";
import AlbumsList from "../../elements/Flatlist/AlbumsList";
import HomeCss from "./TJJHome.module.css";
import ViewMore from "../../elements/view-more/ViewMore";
import EventSlides from "../../elements/event-slides/EventSlides";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ExtractShortMonthDate } from "../../elements/date-extractor/ExtractDate";
import { Context } from "../../Context";
import MetaTags from "../../MetaTags";
import GalleryCardLoader from "../../elements/skeletonLoaders/GalleryCardLoader";
import VideoCardLoader from "../../elements/skeletonLoaders/VideoCardLoader";
import NewsCardLoader from "../../elements/skeletonLoaders/NewsCardLoader";
import MusicCardLoader from "../../elements/skeletonLoaders/MusicCardLoader";
import UpcomingCarnivalCardLoader from "../../elements/skeletonLoaders/UpcomingCarnivalCardLoader";
import EventCardLoader from "../../elements/skeletonLoaders/EventCardLoader";
import axios from "axios";
import { PER_PAGE_COUNT, SERVER_URL } from "../../Constants.ts";
import HomeCarousel from "../../elements/Carousel/HomeCarousel.js";
import ReactGA from "react-ga4";

export default function TJJHome() {
  const [isLoading, setIsLoading] = useState(true);
  const [featuredCarnivals, setFeaturedCarnivals] = useState(null);
  const [featuredIsLoading, setFeaturedIsLoading] = useState(true);
  const [featuredEvents, setFeaturedEvents] = useState(null);
  const [featuredEventsIsLoading, setFeaturedEventsIsLoading] = useState(true);
  const [videos, setVideos] = useState(null);
  const [videosIsLoading, setVideosIsLoading] = useState(true);
  const [galleries, setGalleries] = useState(null);
  const [galleriesIsLoading, setGalleriesIsLoading] = useState(true);
  const [news, setNews] = useState(null);
  const [newsIsLoading, setNewsIsLoading] = useState(true);
  const [blogs, setBlogs] = useState(null);
  const [blogsIsLoading, setBlogsIsLoading] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [reviewsIsLoading, setReviewsIsLoading] = useState(null);
  const navigate = useNavigate();
  const { setPageTitle } = useContext(Context);
  const [width, setWidth] = useState(window.innerWidth);
  const [music, setMusic] = useState(null);
  const [musicIsLoading, setMusicIsLoading] = useState(true);
  const [banners, setBanners] = useState(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "TJJ Home" });
  }, []);

  // The set page title is being used to update the title in the breadcrumbs. setPageTitle has been added in brackets to resolve "React Hook useEffect has a missing dependency: 'setPageTitle'." error.
  useEffect(() => {
    if (setPageTitle) {
      setPageTitle(null);
    }
  }, [setPageTitle]);

  const handleGetFeaturedCarnivals = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_FEATURED_CARNIVALS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_CARNIVALS,
        },
      });
      if (res.status === 200) {
        setFeaturedCarnivals(res.data.data);
        setFeaturedIsLoading(false);
      }
    } catch (err) {
      setFeaturedIsLoading(false);
    }
  };

  const GetMostRecent = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_GALLERIES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.GALLERIES_HOME,
          type: "most_recent",
        },
      });
      if (res.status === 200) {
        if (res?.data?.galleries?.data?.length !== 0) {
          setGalleries(res?.data?.galleries?.data);
        }
        setGalleriesIsLoading(false);
      }
    } catch (err) {
      setGalleriesIsLoading(false);
    }
  };

  const handleGetLatestVideo = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_VIDEOS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.TJJ_HOME_VIDEOS,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        setVideos(res?.data?.data);
        setVideosIsLoading(false);
      }
    } catch (err) {
      setVideosIsLoading(false);
    }
  };

  const getFeaturedEvents = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_EVENTS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.FEATURED_EVENTS,
          type: "featured",
        },
      });
      if (res.status === 200) {
        setFeaturedEvents(res?.data?.data);
        setFeaturedEventsIsLoading(false);
      }
    } catch (err) {
      setFeaturedEventsIsLoading(false);
    }
  };

  const handleGetLatestNews = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_NEWSLETTERS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.TJJ_HOME_NEWS,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        setNews(res?.data?.newsletters?.data);
        setNewsIsLoading(false);
      }
    } catch (err) {
      setNewsIsLoading(false);
    }
  };

  const handleGetTrendingData = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_MUSIC_ALL_ALBUMS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.MUSIC_NEW_RELEASES,
          orderBy: "play_count",
        },
      });
      if (res.status === 200) {
        if (res?.data?.data && res?.data?.data?.length !== 0) {
          setMusic(res?.data?.data);
        }
        setMusicIsLoading(false);
      }
    } catch (err) {
      setMusicIsLoading(false);
    }
  };

  const handleGetNewsArticles = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_NEWSARTICLES, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.TJJ_HOME_NEWS,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        setBlogs(res?.data?.news_articles?.data);
        setBlogsIsLoading(false);
      }
    } catch (err) {
      setBlogsIsLoading(false);
    }
  };

  const handleGetReviews = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ALL_REVIEWS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          page: 1,
          items: PER_PAGE_COUNT.TJJ_HOME_NEWS,
          orderBy: "created_at",
          orderDirection: "desc",
        },
      });
      if (res.status === 200) {
        setReviews(res?.data?.reviews?.data);
        setReviewsIsLoading(false);
      }
    } catch (err) {
      setReviewsIsLoading(false);
    }
  };

  const handleGetHomeBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.HOME_BANNER, {
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        setBanners(res?.data?.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  // This useEffect will be called when we try to resize the window. It will be used to add conditions for different viewports.
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    handleGetFeaturedCarnivals();
    GetMostRecent();
    getFeaturedEvents();
    handleGetLatestVideo();
    handleGetTrendingData();
    handleGetLatestNews();
    handleGetNewsArticles();
    handleGetHomeBanner();
    handleGetReviews();
  }, []);

  const handleClick = (item) => {
    navigate(item);
    window.scrollTo(0, 0);
  };

  return (
    <NavContainer>
      <MetaTags
        author={"Trini Jungle Juice"}
        title={"Home"}
        url={window.location.href}
        type={"website"}
      />
      {isLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", marginBottom: 5 }}
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={600}
        />
      ) : (
        banners && <HomeCarousel data={banners} />
      )}

      {featuredIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        featuredCarnivals &&
        featuredCarnivals?.length !== 0 && (
          <Label label={"Featured Carnivals"} size="lg" />
        )
      )}
      {featuredIsLoading ? (
        <div className={HomeCss.mgb}>
          <Grid container rowSpacing={4} columnSpacing={2}>
            {Array.from({ length: 6 }, (_, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <UpcomingCarnivalCardLoader />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        featuredCarnivals &&
        featuredCarnivals?.length !== 0 && (
          <>
            <Grid container rowSpacing={4} columnSpacing={2}>
              {featuredCarnivals?.map((item, index) => {
                const startDate = ExtractShortMonthDate(item.start_datetime);
                const endDate = ExtractShortMonthDate(item.end_datetime);
                return (
                  <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                    <Card
                      imageProps={{ height: width > 431 ? 208 : "auto" }}
                      title={item.title}
                      imageSrc={item.poster_url}
                      content={
                        <div className={HomeCss.subTitle}>
                          {startDate} to {endDate}
                        </div>
                      }
                      onClick={() => handleClick(`/carnival/${item.slug}`)}
                      textAlign={"center"}
                      showLinearFillShadow={true}
                      size={"md"}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <ViewMore onClick={() => handleClick("/world-carnival")} />
          </>
        )
      )}

      {galleriesIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        galleries &&
        galleries?.length !== 0 && (
          <Label label={"Latest Galleries"} size="lg" />
        )
      )}
      {galleriesIsLoading ? (
        <div className={HomeCss.mgb}>
          <div className={HomeCss.flexDr}>
            {Array.from({ length: 8 }, (_, index) => {
              return (
                <div key={index}>
                  <GalleryCardLoader />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        galleries &&
        galleries?.length !== 0 && (
          <>
            <GalleryList gallery={galleries} />
            <ViewMore
              viewMoreOrFilter={true}
              onClick={() => handleClick("/gallery/all")}
            />
          </>
        )
      )}

      {featuredEventsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        featuredEvents &&
        featuredEvents?.length !== 0 && (
          <Label label={"Featured Events"} size="lg" />
        )
      )}
      {featuredEventsIsLoading ? (
        <div className={HomeCss.mgb}>
          {width > 431 ? (
            <div className={HomeCss.flexDr}>
              {Array.from({ length: 4 }, (_, index) => {
                return <EventCardLoader key={index} />;
              })}
            </div>
          ) : (
            <div style={{ display: "flex", overflowX: "auto" }}>
              {Array.from({ length: 4 }, (_, index) => (
                <div key={index} style={{ marginRight: "10px" }}>
                  <EventCardLoader />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        featuredEvents &&
        featuredEvents?.length !== 0 && (
          <>
            <EventSlides data={featuredEvents} />
            <ViewMore
              viewMoreOrFilter={true}
              onClick={() => handleClick("/events")}
            />
          </>
        )
      )}

      {videosIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        videos &&
        videos?.length !== 0 && <Label label={"Latest Videos"} size="lg" />
      )}
      {videosIsLoading ? (
        <div className={HomeCss.mgb}>
          <div className={HomeCss.flexDr}>
            {Array.from({ length: 6 }, (_, index) => {
              return <VideoCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        videos &&
        videos?.length !== 0 && (
          <>
            <VideoList videos={videos} />
            <ViewMore onClick={() => handleClick("/videos")} />
          </>
        )
      )}

      {musicIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        music &&
        music?.length !== 0 && <Label label={"Check Out Our Music"} size="lg" />
      )}
      {musicIsLoading ? (
        <div className={HomeCss.mgb}>
          <div className={HomeCss.albumsDiv}>
            {Array.from({ length: 8 }, (_, index) => {
              return (
                <div key={index} className={HomeCss.albumsSubDiv}>
                  <MusicCardLoader />;
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        music &&
        music?.length !== 0 && (
          <>
            <AlbumsList albums={music} />
            <ViewMore onClick={() => handleClick("/music")} />
          </>
        )
      )}

      {blogsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        blogs && blogs?.length !== 0 && <Label label={"News"} size="lg" />
      )}
      {blogsIsLoading ? (
        <div className={HomeCss.mgb}>
          <div className={HomeCss.flexDrR}>
            {Array.from({ length: 4 }, (_, index) => {
              return <NewsCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        blogs &&
        blogs?.length !== 0 && (
          <>
            <NewsList news={blogs} isNewsArticle={true} />
            <ViewMore
              viewMoreOrFilter={true}
              onClick={() => handleClick("/news-articles")}
            />
          </>
        )
      )}

      {reviewsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        reviews &&
        reviews?.length !== 0 && <Label label={"Reviews"} size="lg" />
      )}
      {reviewsIsLoading ? (
        <div className={HomeCss.mgb}>
          <div className={HomeCss.flexDrR}>
            {Array.from({ length: 4 }, (_, index) => {
              return <NewsCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        reviews &&
        reviews?.length !== 0 && (
          <>
            <NewsList news={reviews} share={true} />
            <ViewMore
              viewMoreOrFilter={true}
              onClick={() => handleClick("/reviews")}
            />
          </>
        )
      )}

      {newsIsLoading ? (
        <Skeleton
          sx={{ bgcolor: "grey.900", margin: "20px" }}
          variant="text"
          width={100}
        />
      ) : (
        news && news?.length !== 0 && <Label label={"Newsletter"} size="lg" />
      )}

      {newsIsLoading ? (
        <div className={HomeCss.mgb}>
          <div className={HomeCss.flexDrR}>
            {Array.from({ length: 4 }, (_, index) => {
              return <NewsCardLoader key={index} />;
            })}
          </div>
        </div>
      ) : (
        news &&
        news?.length !== 0 && (
          <>
            <NewsList news={news} share={false} />
            <ViewMore onClick={() => handleClick("/newsletters")} />
          </>
        )
      )}
    </NavContainer>
  );
}
