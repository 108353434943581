import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from "react-router";
import NavbarCss from "./Navbar.module.css";
import { MdKeyboardArrowDown, MdKeyboardArrowLeft } from "react-icons/md";
import { IoPersonCircleOutline } from "react-icons/io5";
import { FaYoutube, FaFacebookF, FaTiktok } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { connect, useSelector } from "react-redux";
import { logoutSuccess } from "../../actions";
import Swal from "sweetalert2";
import axios from "axios";
import { SERVER_URL } from "../../Constants.ts";
import { FontSizes } from "../../config.js";
import { auth as firebaseAuth } from "../../screens/auth/FirebaseApp.js";
import { selectPages } from "../../slices/PagesReducer.js";
import DynamicContentInjector from "../DynamicContentInjector/DynamicContentInjector.js";

const Navbar = ({ logoutSuccess, auth, stickyHead = false }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElNav1, setAnchorElNav1] = React.useState(null);
  const [anchorElNavAccounts, setAnchorElNavAccounts] = React.useState(null);
  const [opened, setOpened] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const [bannerAd, setBannerAd] = useState(null);
  const pages = useSelector(selectPages);

  const handleLogout = async () => {
    await firebaseAuth.signOut();
    logoutSuccess();
    navigate("/");
    Swal.fire({
      title: "Logout!",
      text: "You have been successfully logged out.",
      icon: "success",
      color: "#fff",
      background: "#000",
      timer: 2000,
      showConfirmButton: false,
    });
  };

  const accountsLoggedInPages = [
    { name: "My Itinerary", route: `/my-itinerary/${auth?.user?.slug}` },
    {
      name: "My Videos Playlist",
      route: `/my-video-playlist/${auth?.user?.slug}`,
    },
    {
      name: "My Songs Playlist",
      route: `/my-songs-playlist/${auth?.user?.slug}`,
    },
    {
      name: "Subscriptions",
      route: `/subscriptions/${auth?.user?.slug}`,
    },
    { name: "Notifications", route: "/notifications" },
    { name: "Logout", route: "/logout" },
  ];

  const accountsLoggedOutPages = [
    { name: "Login", route: "/login" },
    { name: "Notifications", route: "/login" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleOpenNavMenuAccounts = (event) => {
    setAnchorElNavAccounts(event.currentTarget);
  };

  const handleCloseNavMenuAccounts = () => {
    setAnchorElNavAccounts(null);
  };

  let filterTimeout;

  const timeout = width < 1024 ? 0 : 1000;

  const handleOpenNavMenu1 = (event, index) => {
    clearTimeout(filterTimeout);
    filterTimeout = setTimeout(() => {
      setAnchorElNav1(event);
      setOpened((prev) => [...prev, index]);
    }, timeout);
  };

  const handleCloseNavMenu1 = (index) => {
    for (let i = 0; i < opened.length; i++) {
      if (opened[i] == index) {
        opened.splice(i, 1);
      }
    }
    setAnchorElNav1(null);
  };

  function openLink(item) {
    let getLink;
    if (item === "youtube") {
      getLink = "https://www.youtube.com/trinijunglejuice";
    }
    if (item === "facebook") {
      getLink = "https://www.facebook.com/trinijunglejuice";
    }
    if (item === "instagram") {
      getLink = "https://www.instagram.com/trinijunglejuice/";
    }
    if (item === "tiktok") {
      getLink = "https://www.tiktok.com/@trinijunglejuice";
    }
    const aTag = document.createElement("a");
    aTag.setAttribute("href", getLink);
    aTag.setAttribute("target", "_blank");
    aTag.click();
  }

  const handleConfirmation = () => {
    handleCloseNavMenuAccounts();
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff0000",
      confirmButtonText: "Logout",
      color: "#fff",
      background: "#000",
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogout();
      }
    });
  };

  const handleNewTab = (link) => {
    const aTag = document.createElement("a");
    aTag.setAttribute("href", link);
    aTag.setAttribute("target", "_blank");
    aTag.click();
  };

  const isLoggedIn = auth?.isLoggedIn;

  const handleAccountsNavigate = (item) => {
    if (isLoggedIn) {
      navigate(item, { state: { id: auth?.user?.id } });
    } else {
      navigate(item);
    }
  };

  const accountsPages = isLoggedIn
    ? accountsLoggedInPages
    : accountsLoggedOutPages;

  const handleGetTopBanner = async () => {
    try {
      const res = await axios.get(SERVER_URL.GET_ADS, {
        headers: {
          Accept: "application/json",
        },
        params: {
          type: "top navigation",
        },
      });
      if (res.status === 200) {
        if (res.data) {
          setBannerAd(res.data);
        }
      }
    } catch (err) {
      setBannerAd(null);
    }
  };

  useEffect(() => {
    handleGetTopBanner();
  }, []);

  return (
    <AppBar
      className={NavbarCss.appbarContainer}
      position={stickyHead ? "sticky" : "static"}
    >
      {bannerAd &&
        (bannerAd?.banner_image_url ? (
          <Container className={NavbarCss.fullHeightAds}>
            <img
              className={NavbarCss.BannerAds}
              src={bannerAd?.banner_image_url}
            />
          </Container>
        ) : (
          bannerAd?.content && (
            <Container className={NavbarCss.fullHeightAds}>
              <DynamicContentInjector
                className={NavbarCss.BannerAds}
                htmlContent={bannerAd?.content}
              />
            </Container>
          )
        ))}
      <Container className={NavbarCss.fullHeight}>
        <Toolbar className={NavbarCss.toolbarHeight} disableGutters>
          <img
            src={require("../../assets/images/tjj-logo-nav.png")}
            alt="TJJ Logo"
            className={NavbarCss.tjjLogo}
            onClick={() => navigate("/")}
          />
          {/* Mobile view starts here */}
          <Box className={NavbarCss.navContainer}>
            {location.pathname !== "/global-search" && (
              <SearchIcon
                onClick={() => navigate("/global-search")}
                className={NavbarCss.searchIcon}
              />
            )}

            <IoPersonCircleOutline
              onClick={handleOpenNavMenuAccounts}
              className={NavbarCss.profileIcon}
            />
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNavAccounts}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNavAccounts)}
              onClose={handleCloseNavMenuAccounts}
              sx={{
                display: width < 1025 ? "flex" : "none",
              }}
              MenuListProps={{
                style: {
                  backgroundColor: "#1F1F1F",
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              {accountsPages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    page?.name === "Logout"
                      ? handleConfirmation()
                      : handleAccountsNavigate(page.route);
                  }}
                  className={NavbarCss.buttonNav}
                >
                  {page.name}
                </MenuItem>
              ))}
            </Menu>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon className={NavbarCss.searchIcon} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: width < 1025 ? "flex" : "none",
              }}
              MenuListProps={{
                style: {
                  backgroundColor: "#1F1F1F",
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              <MenuItem>
                <div className={NavbarCss.socials}>
                  <FaYoutube
                    onClick={() => openLink("youtube")}
                    className={NavbarCss.icon}
                  />
                  <FaFacebookF
                    onClick={() => openLink("facebook")}
                    className={NavbarCss.icon}
                  />
                  <RiInstagramFill
                    onClick={() => openLink("instagram")}
                    className={NavbarCss.icon}
                  />
                  <FaTiktok
                    onClick={() => openLink("tiktok")}
                    className={NavbarCss.icon}
                  />
                </div>
              </MenuItem>
              {pages.map((page, index) => (
                <MenuItem key={page.name}>
                  <div className={NavbarCss.NavElements}>
                    {page?.subTabs && (
                      <MdKeyboardArrowLeft
                        className={NavbarCss.caretDown}
                        onClick={(event) =>
                          handleOpenNavMenu1(event.currentTarget, index)
                        }
                      />
                    )}
                    <Button
                      key={page.name}
                      onClick={() => {
                        page.route &&
                          (page?.name === "Store"
                            ? handleNewTab(page.route)
                            : navigate(page.route));
                      }}
                      className={NavbarCss.buttonNav}
                    >
                      {page.name}
                    </Button>
                  </div>
                  {opened.includes(index) && page?.subTabs && (
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav1}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElNav1)}
                      onClose={() => handleCloseNavMenu1(index)}
                      sx={{
                        display: width < 1025 ? "flex" : "none",
                        width: "270px",
                      }}
                      MenuListProps={{
                        style: {
                          backgroundColor: "#1F1F1F",
                          paddingTop: 0,
                          paddingBottom: 0,
                        },
                      }}
                    >
                      {page?.subTabs?.map((p) => (
                        <MenuItem
                          key={p.tabName}
                          onClick={() => {
                            navigate(p.link);
                            handleCloseNavMenu1(index);
                            handleCloseNavMenu();
                          }}
                          className={NavbarCss.buttonNav}
                        >
                          {p.tabName}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* Mobile view ends here */}
          {/* Desktop view starts here */}

          <Box className={NavbarCss.mdBox}>
            {pages.map((page, index) => (
              <div key={page.name}>
                <div
                  className={NavbarCss.NavElements}
                  onMouseEnter={(event) => {
                    page?.subTabs &&
                      handleOpenNavMenu1(event.currentTarget, index);
                  }}
                  onMouseLeave={() => clearTimeout(filterTimeout)}
                >
                  <Button
                    onClick={() => {
                      page.route &&
                        (page?.name === "Store"
                          ? handleNewTab(page.route)
                          : navigate(page.route));
                    }}
                    className={NavbarCss.buttonNav}
                  >
                    {page.name}
                  </Button>
                  {page?.subTabs && (
                    <MdKeyboardArrowDown className={NavbarCss.caretDown} />
                  )}
                </div>

                {opened.includes(index) && page?.subTabs && (
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav1}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElNav1)}
                    onClose={() => handleCloseNavMenu1(index)}
                    className={NavbarCss.display}
                    MenuListProps={{
                      style: {
                        backgroundColor: "#1F1F1F",
                        paddingTop: 1,
                        paddingBottom: 1,
                      },
                    }}
                  >
                    {page?.subTabs?.map((p) => (
                      <MenuItem
                        key={p.tabName}
                        onClick={() => {
                          handleCloseNavMenu1(index);
                          navigate(p.link);
                        }}
                        className={NavbarCss.buttonNav}
                        sx={{
                          padding: 1,
                          fontSize: `${FontSizes.body}px !important`,
                          paddingLeft: 2,
                          paddingRight: 2,
                        }}
                      >
                        {p.tabName}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </div>
            ))}
            <div className={NavbarCss.socialsContainer}>
              <div className={NavbarCss.socials}>
                <FaYoutube
                  onClick={() => openLink("youtube")}
                  className={NavbarCss.icon}
                />
                <FaFacebookF
                  onClick={() => openLink("facebook")}
                  className={NavbarCss.icon}
                />
                <RiInstagramFill
                  onClick={() => openLink("instagram")}
                  className={NavbarCss.icon}
                />
                <FaTiktok
                  onClick={() => openLink("tiktok")}
                  className={NavbarCss.icon}
                />
                <IoPersonCircleOutline
                  onClick={handleOpenNavMenuAccounts}
                  className={NavbarCss.profileIcon}
                />
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNavAccounts}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNavAccounts)}
                  onClose={handleCloseNavMenuAccounts}
                  sx={{
                    display: { sm: "none", md: "block" },
                  }}
                  MenuListProps={{
                    style: {
                      backgroundColor: "#1F1F1F",
                      paddingTop: 1,
                      paddingBottom: 1,
                    },
                  }}
                >
                  {accountsPages.map((page) => (
                    <MenuItem
                      onClick={() => {
                        page.name === "Logout"
                          ? handleConfirmation()
                          : handleAccountsNavigate(page.route);
                      }}
                      className={NavbarCss.buttonNav}
                      key={page.name}
                      sx={{
                        padding: 1,
                        fontSize: `${FontSizes.body}px !important`,
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      {page.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </Box>
          {/* Desktop view Ends here */}

          {location.pathname !== "/global-search" && (
            <div
              onClick={() => navigate("/global-search")}
              className={NavbarCss.search}
            >
              <SearchIcon className={NavbarCss.searchIconMd} />
              Search
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutSuccess })(Navbar);
