function objectToGetParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeExtraCharacters(String(value))}`;
    });

  return params.length > 0 ? `?${params.join("&")}` : "";
}

const encodeExtraCharacters = (str) => {
  return encodeURIComponent(str)
    .replaceAll(/\./g, "") // Encode full stop (.)
    .replaceAll(/'/g, "") // Encode single quote (')
    .replaceAll(/"/g, "") // Encode double quote (")
    .replaceAll(/\(/g, "") // Encode open parenthesis
    .replaceAll(/\)/g, "") // Encode close parenthesis
    .replaceAll(/\:/g, "") // Encode colon parenthesis
    .replaceAll(/!/g, "") // Encode exclamation mark
    .replaceAll(/\*/g, ""); // Encode asterisk
};

function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

export function facebookLink(url, hashtag) {
  return (
    "https://www.facebook.com/sharer.php" +
    objectToGetParams({ u: url }) // Pass true to handle Facebook-specific encoding
  );
}

export function redditLink(url, title) {
  return (
    "https://www.reddit.com/submit" +
    objectToGetParams({
      url,
      title,
    })
  );
}

export function twitterLink(url, title) {
  return (
    "https://twitter.com/intent/tweet" +
    objectToGetParams({
      url,
      text: title,
    })
  );
}

export function whatsappLink(url, title, separator) {
  return (
    "https://" +
    (isMobileOrTablet() ? "api" : "web") +
    ".whatsapp.com/send" +
    objectToGetParams({
      text: title ? title + separator + url : url,
    })
  );
}

export function emailLink(url, subject, body, separator) {
  return (
    "mailto:" +
    objectToGetParams({ subject, body: body ? body + separator + url : url })
  );
}

export const copy = async (link) => {
  await navigator.clipboard.writeText(link);
  alert("Link copied");
};
