import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pages: [
    { name: "Home", route: "/" },
    { name: "Store", route: "https://store.trinijunglejuice.com/" },
    {
      name: "Carnival",
      route: "/carnival",
      subTabs: [
        {
          tabName: "Carnival Home",
          link: "/carnival",
        },
        {
          tabName: "All Carnival",
          link: "/carnivals?orderby=desc",
        },
        {
          tabName: "Carnival Events",
          link: "/events?tag=carnival-coverage",
        },
        {
          tabName: "Carnival Photos",
          link: "/gallery/all?tag=carnival-coverage",
        },
        {
          tabName: "Carnival Videos",
          link: "/videos/all?tag=carnival-coverage",
        },
        {
          tabName: "Carnival News",
          link: "/news-articles?tag=carnival-coverage",
        },
        {
          tabName: "World Carnival Calendar",
          link: "/world-carnival",
        },
        {
          tabName: "Carnival Classifieds",
          link: "/carnival/classifieds",
        },
      ],
    },
    {
      name: "Events",
      route: "/events",
      subTabs: [
        {
          tabName: "Events Home",
          link: "/events",
        },
        {
          tabName: "Browse Events by Tags",
          link: "/events/tags",
        },
      ],
    },
    { name: "Photos", route: "/gallery" },
    { name: "Videos", route: "/videos" },
    {
      name: "Music",
      route: "/music",
      subTabs: [
        {
          tabName: "Music Home",
          link: "/music",
        },
        {
          tabName: "Artist Profiles",
          link: "/music/artists",
        },
        {
          tabName: "Albums",
          link: "/music/albums",
        },
        {
          tabName: "Genres",
          link: "/music/genres",
        },
      ],
    },
    {
      name: "News",
      route: "/news-articles",
      subTabs: [
        {
          tabName: "News Articles",
          link: "/news-articles",
        },
        {
          tabName: "Reviews",
          link: "/reviews",
        },
        {
          tabName: "Newsletters",
          link: "/newsletters",
        },
      ],
    },
    { name: "Contact", route: null },
  ],
};

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload;
    },
  },
});

export const { setPages } = pagesSlice.actions;

export const selectPages = (state) => state.pages.pages;

export default pagesSlice.reducer;
