import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screens/tjj-home/TJJHome";
import CarnivalHome from "./screens/carnival/CarnivalHome";
import GalleryHome from "./screens/gallery/GalleryHome";
import CarnivalDetail from "./screens/carnival/CarnivalDetail";
import MusicHome from "./screens/music/MusicHome";
import AllCarnivals from "./screens/carnival/AllCarnivals";
import WorldCarnival from "./screens/carnival/WorldCarnival";
import Album from "./screens/music/Album";
import Genres from "./screens/music/Genres";
import EventHome from "./screens/event/EventHome";
import EventDetail from "./screens/event/EventDetail";
import VideoScreen from "./screens/music/VideoScreen";
import ArtistHomePage from "./screens/music/ArtistHomePage";
import ArtistPage from "./screens/artist/ArtistPage";
import NewslettersHome from "./screens/newsletters/NewslettersHome";
import NewsletterDetail from "./screens/newsletters/NewsletterDetail";
import GlobalSearch from "./screens/global-search/GlobalSearch";
import ReviewDetail from "./screens/reviews/ReviewDetail";
import VideoHome from "./screens/videos/VideosHome";
import ReviewsHome from "./screens/reviews/ReviewsHome";
import AlbumHome from "./screens/album/AlbumHome";
import AllGalleries from "./screens/gallery/AllGalleries";
import ViewGallery from "./screens/gallery/ViewGallery";
import Promoters from "./screens/promoters/Promoters";
import Category from "./screens/category/Category";
import MyItinerary from "./screens/accounts/MyItinerary";
import AlbumView from "./screens/album/AlbumView";
import ItineraryDetail from "./screens/accounts/ItineraryDetail";
import Subscriptions from "./screens/accounts/Subscriptions";
import { Context } from "./Context";
import { useEffect, useMemo, useState } from "react";
import LayoutWrapper from "./elements/layout-wrapper/LayoutWrapper";
import NewsArticlesHome from "./screens/news-articles/NewsArticlesHome";
import Tags from "./screens/tags/Tags";
import Authors from "./screens/author/Author";
import LoginPage from "./screens/auth/LoginPage";
import { useDispatch, useSelector } from "react-redux";
import Register from "./screens/auth/Register";
import NewsArticleDetails from "./screens/news-articles/NewsArticleDetails";
import AllVideosWithFilter from "./screens/videos/AllVideosWithFilter";
import ForgotPassword from "./screens/auth/ForgotPassword";
import ConfirmPassword from "./screens/auth/ConfirmPassword";
import MyVideoPlaylists from "./screens/accounts/MyVideoPlaylists";
import PlaylistDetail from "./screens/accounts/PlaylistDetail";
import CarnivalClassifieds from "./screens/carnival-classifieds/CarnivalClassifieds";
import CarnivalClassifiedDetail from "./screens/carnival-classifieds/CarnivalClassifiedDetail";
import CarnivalInbox from "./screens/carnival-classifieds/CarnivalInbox";
import NewClassified from "./screens/carnival-classifieds/NewClassified";
import ClassifiedsChatbox from "./screens/carnival-classifieds/ClassifiedsChatbox";
import MySongsPlaylists from "./screens/accounts/MySongsPlaylists";
import SongsPlaylistDetail from "./screens/accounts/SongsPlaylistDetail";
import { setQueue } from "./actions";
import Playlist from "./screens/playlists/Playlist";
import PlaylistView from "./screens/playlists/PlaylistView";
import NoPageFound from "./screens/no-data/NoPageFound";
import VideoPlaylistView from "./screens/playlists/VideoPlaylistView";
import ItineraryView from "./screens/playlists/ItineraryView";
import Page from "./screens/pages/Page";
import Notifications from "./screens/accounts/Notifications";
import ReactGA from "react-ga4";

function App() {
  const [pageTitle, setPageTitle] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState();
  const login = useSelector((state) => state?.auth?.isLoggedIn);
  const dispatch = useDispatch();
  // ReactGA.initialize('G-74667VNWVY');
  ReactGA.initialize("G-RKW2KBQL3G");

  useEffect(() => {
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      const parsedAuthState = JSON.parse(storedAuthState);
      const loggedIn = parsedAuthState?.isLoggedIn ?? false;
      setIsLoggedIn(loggedIn);
      dispatch({ type: "SET_AUTH_STATE", payload: parsedAuthState });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useMemo(() => {
    if (login) {
      setIsLoggedIn(login);
    }
  }, [login]);

  useEffect(() => {
    const storedQueue = localStorage.getItem("queue");
    if (storedQueue && storedQueue?.length !== 0) {
      const parsedQueue = JSON.parse(storedQueue);
      dispatch(setQueue(parsedQueue));
    }
  }, []);

  return (
    <Context.Provider value={{ pageTitle, setPageTitle }}>
      <LayoutWrapper>
        <Router>
          <Routes>
            <Route element={<ConfirmPassword />} path="/confirm-password" />
            <Route element={<ForgotPassword />} path="/forgot-password" />
            <Route element={<Home />} path="/" />
            <Route element={<CarnivalHome />} path="/carnival" />
            <Route element={<CarnivalDetail />} path="/carnival/:slug" />
            <Route element={<MusicHome />} path="/music" />
            <Route element={<AllCarnivals />} path="/carnivals" />
            <Route element={<WorldCarnival />} path="/world-carnival" />
            <Route element={<Album />} path="/music/albums" />
            <Route element={<Genres />} path="/music/genres" />
            <Route element={<ArtistHomePage />} path="/music/artists" />
            <Route element={<EventHome />} path="/events" />
            <Route element={<EventDetail />} path="/events/:slug" />
            <Route element={<VideoScreen />} path="/videos/:slug" />
            <Route element={<ArtistPage />} path="/music/artists/:slug" />
            <Route element={<NewslettersHome />} path="/newsletters" />
            <Route element={<NewsletterDetail />} path="/newsletters/:slug" />
            <Route element={<GlobalSearch />} path="/global-search" />
            <Route element={<ReviewDetail />} path="/reviews/:slug" />
            <Route element={<VideoHome />} path="/videos" />
            <Route element={<ReviewsHome />} path="/reviews" />
            <Route element={<AlbumHome />} path="/music/albums/all" />
            <Route element={<AlbumView />} path="/music/albums/:slug" />
            <Route element={<GalleryHome />} path="/gallery" />
            <Route element={<AllGalleries />} path="/gallery/all" />
            <Route element={<AllGalleries />} path="/gallery/most-liked" />
            <Route element={<AllGalleries />} path="/gallery/highly-rated" />
            <Route element={<AllGalleries />} path="/gallery/top-viewed" />
            <Route
              element={<AllGalleries />}
              path="/gallery/this-time-last-year"
            />
            <Route element={<ViewGallery />} path="/gallery/view/:slug" />
            <Route
              element={<ViewGallery />}
              path="/gallery/view/:slug/:image_id"
            />
            <Route element={<GalleryHome />} path="/gallery/:image_id" />
            <Route element={<Promoters />} path="/promoters" />
            <Route element={<Category />} path="/events/categories" />
            <Route element={<Category />} path="/newsletters/categories" />
            <Route element={<Category />} path="/reviews/categories" />
            <Route element={<Authors />} path="/reviews/authors" />
            <Route element={<Tags />} path="/reviews/tags" />
            <Route element={<Authors />} path="/news-articles/authors" />
            <Route element={<Tags />} path="/news-articles/tags" />
            <Route element={<Category />} path="/news-articles/categories" />
            <Route element={<Category />} path="/videos/categories" />
            <Route element={<Tags />} path="/newsletters/tags" />
            <Route element={<Tags />} path="/events/tags" />
            <Route element={<Tags />} path="/videos/tags" />
            <Route element={<Tags />} path="/carnival/classifieds/tags" />
            <Route element={<Authors />} path="/newsletters/authors" />
            {isLoggedIn && (
              <>
                <Route element={<MyItinerary />} path="/my-itinerary/:id" />
                <Route
                  element={<ItineraryDetail />}
                  path="/my-itinerary/:id/:slug"
                />
                <Route
                  element={<MyVideoPlaylists />}
                  path="/my-video-playlist/:id"
                />
                <Route
                  element={<MySongsPlaylists />}
                  path="/my-songs-playlist/:id"
                />
                <Route
                  element={<SongsPlaylistDetail />}
                  path="/my-songs-playlist/:id/:slug"
                />
                <Route
                  element={<PlaylistDetail />}
                  path="/my-video-playlist/:id/:slug"
                />
                <Route element={<Subscriptions />} path="/subscriptions/:id" />
                <Route element={<Notifications />} path="/notifications" />
              </>
            )}
            <Route element={<NewsArticlesHome />} path="/news-articles" />
            <Route
              element={<NewsArticleDetails />}
              path="/news-articles/:slug"
            />
            <Route element={<LoginPage />} path="/login" />
            <Route element={<Register />} path="/register" />
            <Route element={<AllVideosWithFilter />} path="/videos/featured" />
            <Route element={<AllVideosWithFilter />} path="/videos/popular" />
            <Route
              element={<AllVideosWithFilter />}
              path="/videos/top-trending"
            />
            <Route element={<AllVideosWithFilter />} path="/videos/all" />
            <Route
              element={<CarnivalClassifieds />}
              path="/carnival/classifieds"
            />
            <Route
              element={<CarnivalClassifiedDetail />}
              path="/carnival/classifieds/:slug"
            />
            <Route
              element={<CarnivalInbox />}
              path="/carnival/classifieds/:id/inbox"
            />
            <Route
              element={<ClassifiedsChatbox />}
              path="/carnival/classifieds/:id/inbox/chatbox"
            />
            <Route
              element={<NewClassified />}
              path="/carnival/classifieds/:id/create"
            />
            <Route element={<Playlist />} path="/music/playlist" />
            <Route element={<PlaylistView />} path="/music/playlist/:slug" />
            <Route
              element={<VideoPlaylistView />}
              path="/videos/playlist/:slug"
            />
            <Route element={<ItineraryView />} path="/events/itinerary/:slug" />
            <Route element={<Page />} path="/pages/:slug" />
            <Route element={<NoPageFound />} path="*" />
          </Routes>
        </Router>
      </LayoutWrapper>
    </Context.Provider>
  );
}

export default App;
