import React, { useContext, useEffect } from "react";
import NavContainer from "../../elements/NavContainer/NavContainer";
import NoData from "../../elements/no-data/NoData";
import { Context } from "../../Context";

export default function NoPageFound() {
  const { setPageTitle } = useContext(Context);

  useEffect(() => {
    setPageTitle("No Data");
  },[]);

  return (
    <NavContainer>
      <NoData error={true} />
    </NavContainer>
  );
}
